import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import UIDropZone from 'src/UIComponents/Dropzone/UIDropzone'

const FileDrop = ({
  supportedMimeTypes,
  maxSize,
  onDrop,
  file,
  changeFileLabel,
  categoryLabel,
  categoryIconSource,
  onMaxSizeError,
  onMimeTypeError,
  onDragEnter,
  onDragLeave,
  externalError
}) => {
  const [type, setType] = useState()
  const [blob, setBlob] = useState()
  const [fileMaxSizeError, setFileMaxSizeError] = useState(false)
  const [fileTypeError, setFileTypeError] = useState(false)

  const handleFileDrop = files => {
    try {
      const img = new Image()

      img.onload = () => {
        onDrop && onDrop(files.map(file => ({
          file,
          preview: URL.createObjectURL(file)
        })))
      }

      files.forEach(file => {
        const isPDF = file.type === 'application/pdf'
        if (isPDF) {
          const blob = URL.createObjectURL(new Blob([file], { type: 'application/pdf' }))
          setBlob(blob)
          setType('pdf')
          onDrop && onDrop(files.map(file => ({
            file,
            preview: '/assets/icons/file/file.svg'
          })))
        } else {
          setBlob(null)
          setType('bitmap')
          img.src = URL.createObjectURL(file)
        }
      })

      setFileMaxSizeError(false)
      onMaxSizeError(false)
      setFileTypeError(false)
      onMimeTypeError(false)
    } catch (e) {
      console.error(e)
    }
  }

  const handleFileDropRejected = files => {
    if (files[0].file.size > maxSize) {
      setFileMaxSizeError(true)
      onMaxSizeError(true)
    }

    if (!supportedMimeTypes.includes(files[0].file.type)) {
      setFileTypeError(true)
      onMimeTypeError(true)
    }
  }

  return (
    <Dropzone
      accept={supportedMimeTypes}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      maxSize={maxSize}
      multiple={false}
      onDrop={handleFileDrop}
      onDropRejected={handleFileDropRejected}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <UIDropZone
          isDragActive={isDragActive}
          changeFileLabel={changeFileLabel}
          categoryIconSource={categoryIconSource}
          categoryLabel={categoryLabel}
          fileMaxSizeError={fileMaxSizeError}
          fileTypeError={fileTypeError}
          externalError={externalError}
          inputProps={{ ...getInputProps() }}
          rootProps={{ ...getRootProps() }}
          previewSrc={blob || file || ''}
          type={type}
        />
      )}
    </Dropzone>
  )
}

FileDrop.propTypes = {
  supportedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number,
  onDrop: PropTypes.func,
  file: PropTypes.string,
  changeFileLabel: PropTypes.string,
  categoryLabel: PropTypes.string,
  categoryIconSource: PropTypes.string,
  onMaxSizeError: PropTypes.func,
  onMimeTypeError: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  externalError: PropTypes.bool
}

export default FileDrop
