import React from 'react'
import t from 'tcomb-form'
import SelectMerchantCodeComponent from '../SelectMerchantCodeComponent/SelectMerchantCodeComponent'

class SelectMerchantCodeFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <SelectMerchantCodeComponent
        id={locals.config.id}
        label={locals.label}
        value={locals.value}
        disabled={locals.config.disabled}
        hasError={locals.hasError}
        errorMessage={locals.error}
        onChange={selected => locals.onChange(selected)}
      />
    )
  }
}

export default SelectMerchantCodeFactory
