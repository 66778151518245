import React, { useEffect } from 'react'
import { getTotalAndNextFutureTransfers } from '../../Actions/futureTransfers'
import { useDispatch, useSelector } from 'react-redux'

import FutureTransferHeaderInfoUI from 'src/UIComponents/FutureTransfer/FutureTransferHeaderInfo'

const FutureTransferHeaderInfo = () => {
  const dispatch = useDispatch()

  const {
    nextFutureTransfer,
    totalAmountFutureTransfer,
    sellerId,
    nextFutureTransferLoading,
    nextFutureTransferError,
    totalAmountFutureTransferLoading,
    totalAmountFutureTransferError
  } = useSelector(({ futureTransfers, context }) => ({
    nextFutureTransfer: futureTransfers.nextFutureTransfer,
    nextFutureTransferLoading: futureTransfers.nextFutureTransferRequesting,
    nextFutureTransferError: futureTransfers.nextFutureTransferError,
    totalAmountFutureTransfer: futureTransfers.totalAmount,
    totalAmountFutureTransferLoading: futureTransfers.totalAmountRequesting,
    totalAmountFutureTransferError: futureTransfers.totalAmountError,
    sellerId: context.seller?.id
  }))

  useEffect(() => {
    if (sellerId) {
      // dispatch(getNextFutureTransfer())
      dispatch(getTotalAndNextFutureTransfers())
    }
  }, [sellerId])

  return (
    <FutureTransferHeaderInfoUI
      nextFutureTransfer={nextFutureTransfer}
      nextFutureTransferLoading={nextFutureTransferLoading}
      nextFutureTransferError={nextFutureTransferError}
      totalAmountFutureTransfer={totalAmountFutureTransfer}
      totalAmountFutureTransferLoading={totalAmountFutureTransferLoading}
      totalAmountFutureTransferError={totalAmountFutureTransferError}
      action={() => dispatch(getTotalAndNextFutureTransfers())}
    />
  )
}

export default FutureTransferHeaderInfo
