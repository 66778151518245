import React from 'react'
import t from 'tcomb-form'
import SelectBankCodeComponent from '../SelectBankCodeComponent/SelectBankCodeComponent'
import { v4 as UUID4 } from 'uuid'

class SelectBankCodeFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <SelectBankCodeComponent
        uuid={UUID4()}
        id={locals.config.id}
        label={locals.label}
        value={locals.value}
        items={locals.config.options}
        disabled={locals.config.disabled}
        hasError={locals.hasError}
        errorMessage={locals.error}
        onChange={value => locals.onChange({ value })}
      />
    )
  }
}

export default SelectBankCodeFactory
