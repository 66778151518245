import React from 'react'
import t from 'tcomb-form'
import SelectMultiplyWithGroupComponent from '../SelectMultiplyWithGroup/SelectMultiplyWithGroupComponent'

export default class GroupSelectionFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <SelectMultiplyWithGroupComponent
        values={locals.value}
        listItems={locals.config.listItems}
        hasError={locals.hasError}
        errorMessage={locals.error}
        label={locals.label}
        placeholder={locals.config.placeholder}
        placeholderSearch={locals.config.placeholderSearch}
        notFoundLabel={locals.config.notFoundLabel}
        onSelect={locals.onChange}
      />
    )
  }
}
