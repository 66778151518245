import React from 'react'
import t from 'tcomb-form'
import InputForm from '../../UIComponents/InputForm/InputForm'

class NumberFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <InputForm
        id={locals.config.id}
        type={locals.type}
        label={locals.label}
        value={locals.value}
        step='0.01'
        maxLength={locals.config.maxLength}
        placeholder={locals.config.placeholder || ''}
        disabled={locals.config.disabled}
        hasError={locals.hasError}
        errorMessage={locals.error}
        lock={locals.config.lock}
        onChange={(e) => {
          let { value } = e.target
          value = value.replace(/\D/g, '')
          locals.onChange(value)
        }}
      />
    )
  }
}

export default NumberFactory
