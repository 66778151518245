import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getTransactionsSucceededDetail } from 'src/Actions/kpi'

import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import KPITransactionsSucceeded from 'src/UIComponents/KPIDetails/KPITransactionsSucceeded'
import ErrorState from '../../UIComponents/ErrorState/ErrorState'

import { darkerGray, darkGray } from '../../Styles/settings/Constants'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const TransactionsSucceeded = ({ history, onClosePath }) => {
  const { color } = useSelector(({ custom }) => ({
    color: custom.color
  }))

  const { period, amount, count, histogram, groupedBy, topHits, request, error } = useSelector(
    ({ kpi: { period, transactions: { succeeded } } }) => ({
      ...succeeded,
      period
    })
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTransactionsSucceededDetail())
  }, [ period ])

  return (
    <Sidebar show>
      <HeaderSidebar
        uppercase
        title={<><strong>Vendas</strong> Aprovadas</>}
        handleClose={() => history.push(onClosePath)}
      />
      {error
        ? <SidebarContainer><ErrorState
          iconSrc='/assets/icons/alerts/alert.svg'
          title='Desculpe não conseguimos carregar os gráficos.'
          description='Acho que aconteceu alguma coisa de errado.'
          color={darkGray}
          iconColor={darkerGray}
        /></SidebarContainer>
        : <KPITransactionsSucceeded
          amount={amount}
          count={count}
          color={color}
          barChartData={histogram}
          pieChartData={groupedBy}
          topHits={topHits}
          isLoading={request}
          period={period}
        />
      }
    </Sidebar>
  )
}

TransactionsSucceeded.propTypes = {
  history: PropTypes.object,
  onClosePath: PropTypes.string
}

TransactionsSucceeded.defaultProps = {
  onClosePath: '/vendas'
}

export default withRouter(TransactionsSucceeded)
