import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from '../../../UIComponents/Modal/Modal'
import ButtonDefault from '../../../UIComponents/Button/ButtonDefault'
import Label from '../../../UIComponents/Label/Label'
import { bigText, darkerGray, normalText } from '../../../Styles/settings/Constants'

export const ChangeResourcesPopup = ({ handleNotSavePopup, handleSaveChanges, setShowChangeSettingsPopup }) => {
  return (
    <Modal handleOnClose={() => setShowChangeSettingsPopup(false)} styleProps={{ maxWidth: '364px', height: '307px', padding: '40px', display: 'flex' }}>
      <MainContainer>
        <Label fontSize={bigText} textAlign={'center'}><strong>Alterar Recursos do Minha Conta</strong></Label>
        <Label
          color={darkerGray}
          textAlign={'center'}
          fontSize={normalText}
        >
           Ao salvar, as modificações alteram <strong>visibilidade dos recursos dos estabelecimentos</strong>
        </Label >
        <ButtonsContainer auto>
          <ButtonDefault widthAuto outline ghost onClick={handleNotSavePopup}>
              Não salvar
          </ButtonDefault>
          <ButtonDefault widthAuto onClick={handleSaveChanges} >
              Salvar alterações
          </ButtonDefault>
        </ButtonsContainer>
      </MainContainer>
    </Modal>
  )
}

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:24px;
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

ChangeResourcesPopup.propTypes = {
  handleNotSavePopup: PropTypes.func,
  handleSaveChanges: PropTypes.func,
  setShowChangeSettingsPopup: PropTypes.func
}
