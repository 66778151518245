import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles/Loader.scss'

class Loader extends Component {
  constructor (props) {
    super(props)

    this.state = {
      enable: true
    }
  }

  render () {
    return (
      <div className='loader-wrapper'>
        <div className={`loader-container ${this.props.position || ''} ${this.props.className || ''}`}>
          <div
            className={`spinner ${this.props.size}`}
            style={{ borderColor: this.props.color || '#ccc' }} />
        </div>
      </div>
    )
  }
}

Loader.propTypes = {
  position: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string
}

export default Loader
