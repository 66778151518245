import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import t from 'tcomb-form'
import { isEmpty } from 'lodash'
import { v4 as UUID4 } from 'uuid'

import InputDatePicker from 'src/UIComponents/InputDatePicker/InputDatePicker'
import DatePicker from 'src/UIComponents/DatePicker/DatePicker'

import { white } from 'src/Styles/settings/Constants'
import onClickOutside from 'react-onclickoutside'

class DatePickerFactory extends t.form.Component {
  getTemplate () {
    return (locals) => {
      return <Picker locals={locals} uuid={UUID4()} />
    }
  }
}

const DatePickerInputContainer = styled.div`
  padding: 0;
  margin: 20px 0 0 0;
  position: relative;
  width: 100%;

  & > :nth-child(2) {
    position: absolute;
    top: 0;
    left: 106%;

    &:after {
      content: '';
      position: absolute;
      left: -3.7%;
      top: 10%;
      border-top: 8px solid transparent;
      border-right: 8px solid ${white};
      border-left: none;
      border-bottom: 8px solid transparent;
    }
  }

  &.is-position-bottom  > :nth-child(2) {
    transform: translate(-100%, 30px);
    z-index: 3;

    &:after {
      transform: rotate(90deg);
      top: 0;
      right: 10%;
      left: auto;
    }
  }
`

export default DatePickerFactory

const PickerComponent = ({
  uuid,
  locals: { config, label, value, onChange, hasError, error }
}) => {
  const [isOpen, setOpen] = useState(false)

  const [date, setDate] = useState(value)

  PickerComponent[`${uuid}-handleClickOutside`] = () => setOpen(false)

  const toggleDatePicker = () => {
    if (config.disablePicker === true) {
      setOpen(false)
    } else {
      setOpen(!isOpen)
    }
  }

  const handleDateInputValue = (inputValue) => {
    if (inputValue && moment(inputValue, 'DD/MM/YYYY', true).isValid()) {
      setDate(moment(inputValue, 'DD/MM/YYYY').toDate())
    }
    if (isEmpty(inputValue)) {
      setDate()
    }
  }

  const parseDate = () => {
    if (value == null) return ''

    if (date && moment(date, 'DD/MM/YYYY').isValid()) {
      return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
    }
  }

  const handleDatePickerChange = (dateObject) => {
    setDate(dateObject)
    setOpen(false)
  }

  useEffect(() => {
    onChange(date)
  }, [date])

  return (
    <DatePickerInputContainer className={`is-position-${config.position || 'bottom'}`}>
      <InputDatePicker
        id={config.id}
        label={label}
        placeholder={config.placeholder || ''}
        value={parseDate()}
        onChange={handleDateInputValue}
        onFocus={toggleDatePicker}
        disabled={config.disabled}
        hasError={hasError}
        errorMessage={error}
        lock={config.lock}
        showIcon={config.showIcon}
        disablePicker={config.disablePicker}
      />

      {isOpen && (
        <DatePicker
          maxDate={config.maxDate}
          minDate={config.minDate}
          shownDate={config.maxDate}
          date={date}
          onChange={handleDatePickerChange}
        />
      )}
    </DatePickerInputContainer>
  )
}

PickerComponent.propTypes = {
  locals: PropTypes.any,
  uuid: PropTypes.string
}

const clickOutsideConfig = {
  handleClickOutside: ({ props: { uuid } }) => PickerComponent[`${uuid}-handleClickOutside`]
}

const Picker = onClickOutside(PickerComponent, clickOutsideConfig)
