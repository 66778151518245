import React from 'react'
import t from 'tcomb-form'
import InputForm from '../../UIComponents/InputForm/InputForm'
import { toDecimal } from '../../Utils/Utils'

class DecimalFactory extends t.form.Component {
  decimalMask = value => {
    if (!value) return ''
    return toDecimal(value)
  }

  _maskedValue = value => {
    value = this.decimalMask(value)
    return value
  }

  getTemplate () {
    return (locals) => (
      <InputForm
        id={locals.config.id}
        type={locals.type}
        label={locals.label}
        value={this._maskedValue(locals.value)}
        maxLength={locals.config.maxLength}
        placeholder={locals.config.placeholder || ''}
        disabled={locals.config.disabled}
        hasError={locals.hasError}
        errorMessage={locals.error}
        lock={locals.config.lock}
        onChange={(e) => {
          let { value } = e.target
          value = value.replace(/\D/g, '')
          value = parseFloat(value) / 100
          if (isNaN(value)) return
          locals.onChange(value.toString())
        }}
      />
    )
  }
}

export default DecimalFactory
