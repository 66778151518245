import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import Grid from 'src/UIComponents/Grid/Grid'
import Title from '../../UIComponents/Title/Title'
import { Table } from './Table'
import TitleInfoPage from 'src/UIComponents/Title/TitleInfoPage'
import Label from '../../UIComponents/Label/Label'
import { darkerGray } from '../../Styles/settings/Constants'
import Disclaimer from 'src/UIComponents/Disclaimer/Disclaimer'
import { isEqual } from 'lodash'
import { ForgotToSavePopup } from './Popups/ForgotToSavePopup'
import { ChangeResourcesPopup } from './Popups/ChangeResourcesPopup'
import { ReturnDefaultConfigPopup } from './Popups/ReturnDefaultConfigPopup'
import { createFeatureFlags, getFeatureFlags } from '../../Actions/feature-flags'
import Loader from '../Loader/Loader'
import createToast from '../../Utils/createToast'

const FeatureFlagsComponent = () => {
  const [hasSavedChangesInLeaveRoute, setHasSavedChangesInLeaveRoute] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState()
  const [, setEditMode] = useState(false)
  const [values, setValues] = useState()
  const [showChangeSettingsPopup, setShowChangeSettingsPopup] = useState(false)
  const [showDefaultSettingsPopup, setShowDefaultSettingsPopup] = useState(false)

  const [showLoader, setShowLoader] = useState(false)
  const [checkboxIsActive, setCheckboxIsActive] = useState(false)

  const [loading, setLoading] = useState('')
  const [error, setError] = useState(null)
  const [updatedAt, setUpdatedAt] = useState(null)
  const [resourcesList, setResourcesList] = useState([
    { order: 1, attributeName: 'receivableEnabled', attributeLabel: 'Agenda de recebíveis', active: true },
    { order: 2, attributeName: 'chargebackEnabled', attributeLabel: 'Estorno', active: true },
    { order: 3, attributeName: 'posEnabled', attributeLabel: 'Habilitar POS', active: true },
    { order: 4, attributeName: 'salesEnabled', attributeLabel: 'Nova venda via boleto bancário', active: true },
    { order: 5, attributeName: 'creditCardSalesEnabled', attributeLabel: 'Nova venda via cartão de crédito', active: true },
    { order: 6, attributeName: 'payoutEnabled', attributeLabel: 'Politica de recebimento (Payout)', active: true },
    { order: 7, attributeName: 'transfersEnabled', attributeLabel: 'Realizar transferências', active: true },
    { order: 8, attributeName: 'plansFeesEnabled', attributeLabel: 'Visualizar planos e taxas', active: true },
    { order: 9, attributeName: 'balancesEnabled', attributeLabel: 'Pagar contas', active: true }
  ])

  const {
    themeColor
  } = useSelector(({ custom }) => ({
    themeColor: custom.color
  }))

  useEffect(() => {
    const asyncCall = async () => {
      setLoading(true)
      setError(null)
      handleGetFeatureFlags()
      setLoading(false)
    }
    asyncCall()
  }, [])

  const handleGetFeatureFlags = async () => {
    try {
      const response = await getFeatureFlags()
      if (response.error) {
        setError(response)
      } else {
        setUpdatedAt(response.updatedAt)
        delete response.updatedAt

        const resources = Object.keys(response).map((key) => {
          return {
            ...resourcesList.find((resource) => resource.attributeName === key),
            active: response[key]
          }
        })

        resources.sort((a, b) => a.order - b.order)
        setResourcesList(resources)
      }
    } catch (error) {
      setError(error)
    }
  }

  const editSubmit = () => {
    setShowDisclaimer(null)
    setHasSavedChangesInLeaveRoute(true)
  }

  const handleEditMode = () => {
    setEditMode((prev) => !prev)
  }

  const handleClose = () => {
    setValues(null)
    setShowDisclaimer(null)
    handleEditMode()
    createToast('error', 'As alterações foram canceladas e não serão salvas.', 400)
  }

  const handleNotSavePopup = () => {
    setShowChangeSettingsPopup(false)
    setShowDefaultSettingsPopup(false)
    handleEditMode()
    createToast('error', 'As alterações foram canceladas e não serão salvas.', 400)
  }

  const handleSavePopup = () => {
    setShowDisclaimer(null)
    setShowChangeSettingsPopup(false)
    setShowDefaultSettingsPopup(false)
    editSubmit()
  }

  const handleSaveChanges = async () => {
    handleSavePopup()

    try {
      await handleSaveSettings()
      setCheckboxIsActive(false)
      createToast('success', 'Tudo certo! Suas alterações foram salvas com sucesso.', 200)
    } catch (e) {
      console.error(e)
      createToast('error', 'Ops... Parece que ocorreu um erro ao tentar salvar. Tente novamente.', 500)
    }
  }

  const disclaimerOptions = {
    edit: {
      message: <ForgotToSavePopup
        handleClose={handleClose}
        checkboxIsActive={checkboxIsActive}
        setCheckboxIsActive={setCheckboxIsActive}
        handleSaveChanges={handleSaveChanges}
      />
    }
  }

  const handleRouteLeave = () => {
    if (isEqual(null, values) || hasSavedChangesInLeaveRoute) {
      setShowDisclaimer(null)
      return true
    }
    setShowDisclaimer('edit')
    return false
  }

  const handleDefaultSettings = async () => {
    const payload = {
      'receivableEnabled': true,
      'chargebackEnabled': true,
      'posEnabled': true,
      'balancesEnabled': true,
      'payoutEnabled': true,
      'salesEnabled': true,
      'creditCardSalesEnabled': true,
      'transfersEnabled': true,
      'plansFeesEnabled': true
    }

    handleSavePopup()

    try {
      setShowLoader(true)
      await createFeatureFlags(payload)
      handleGetFeatureFlags()
      setCheckboxIsActive(false)
      setShowLoader(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSaveSettings = async () => {
    const payload = {}
    try {
      setShowLoader(true)
      resourcesList.forEach((key) => {
        payload[key.attributeName] = key.active
      })

      await createFeatureFlags(payload)
      handleGetFeatureFlags()
      setShowLoader(false)
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
  }

  return (
    <>
      <Prompt message={handleRouteLeave} />
       <>
         {showLoader === true && <Loader position='center' size='medium' color={themeColor} />}
         <Grid noPadding>
           <TitleInfoPage>Gestão de estabelecimentos {'>'} </TitleInfoPage>
           <Grid.Row>
             <Grid.Col offset={1} cols={15}>
               <Title big noPadding data-test='customize-resources-title'>
                 <strong>Recursos </strong> do minha conta
               </Title>
             </Grid.Col>
           </Grid.Row>
           <Grid.Row >
             <Grid.Col offset={1} cols={15}>
               <Label color={darkerGray}> <Label bold>Ative</Label> ou <Label bold>desative</Label> algumas funcionalidades disponíveis na conta dos estabelecimentos comerciais.
                  Ao realizar as alterações, os estabelecimentos não terão mais visibilidade dos recursos desabilitados.
               </Label>
             </Grid.Col>
           </Grid.Row>
           <Table
             resourcesList={resourcesList}
             setResourcesList={setResourcesList}
             loading={loading}
             error={error}
             updatedAt={updatedAt}
             setShowDefaultSettingsPopup={setShowDefaultSettingsPopup}
             setShowChangeSettingsPopup={setShowChangeSettingsPopup}
           />
         </Grid>

         <Grid.Row noMargin>
           {showDisclaimer && (
             <Grid.Col cols={16}>
               <Disclaimer
               >
                 {disclaimerOptions[showDisclaimer].message}
               </Disclaimer>
             </Grid.Col>
           )}
         </Grid.Row>

       </>
      { showChangeSettingsPopup === true && <ChangeResourcesPopup
        handleNotSavePopup={handleNotSavePopup}
        checkboxIsActive={checkboxIsActive}
        setCheckboxIsActive={setCheckboxIsActive}
        handleSaveChanges={handleSaveChanges}
        setShowChangeSettingsPopup={setShowChangeSettingsPopup}
      />
      }
      { showDefaultSettingsPopup === true && <ReturnDefaultConfigPopup
        handleNotSavePopup={handleNotSavePopup}
        checkboxIsActive={checkboxIsActive}
        setCheckboxIsActive={setCheckboxIsActive}
        handleDefaultSettings={handleDefaultSettings}
        setShowDefaultSettingsPopup={setShowDefaultSettingsPopup}
      />
      }
    </>
  )
}

export default FeatureFlagsComponent
