import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  TitleContainer,
  Title,
  Divider,
  MessageContainer,
  Message,
  Button,
  Display
} from './styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as authActions from 'src/Actions/authentication'
import history from 'src/Configs/history'

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...authActions
    },
    dispatch
  )

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo, error)
  }

  handleClick(e) {
    e.preventDefault()
    this.props.logout()
    history.push('/login')
    this.setState({ hasError: false })
  }

  render() {
    const { children, permissionProfile, isBackoffice } = this.props

    const isBackofficeError = !permissionProfile && isBackoffice

    if (this.state.hasError) {
      return (
        <Container>
          <Display>
            <TitleContainer>
              <Title>
                {isBackofficeError
                  ? 'Acesso backoffice 🛠'
                  : 'Bem, isso é estranho 🤔'}
              </Title>
            </TitleContainer>
            <Divider />
            <MessageContainer>
              <Message>
                {isBackofficeError
                  ? 'Este usuário não tem permissão para acessar via backoffice. Reveja o perfil do usuário no backoffice.'
                  : 'Aconteceu um erro inesperado. Identificamos o erro e vamos consertá-lo o quanto antes.'}
              </Message>
              <Button widthAuto onClick={(e) => this.handleClick(e)}>
                Recarregar
              </Button>
            </MessageContainer>
          </Display>
        </Container>
      )
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  logout: PropTypes.func,
  children: PropTypes.node,
  permissionProfile: PropTypes.string,
  isBackoffice: PropTypes.bool
}

export default connect(null, mapDispatchToProps)(ErrorBoundary)
