import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getTransactionsFailedDetail } from '../../Actions/kpi'

import { toMoney } from '../../Utils/Utils'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import KPITransactionsFailed from '../../UIComponents/KPIDetails/KPITransactionsFailed'
import ErrorState from '../../UIComponents/ErrorState/ErrorState'

import { darkerGray, darkGray } from '../../Styles/settings/Constants'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const TransactionsFailed = ({ history, onClosePath }) => {
  const dispatch = useDispatch()
  const { color, period, amount, count, groupedBy: pieData, request, error } = useSelector(({ custom, kpi: { period, transactions: { failed } } }) => ({
    ...custom,
    ...failed,
    period
  }))

  useEffect(() => {
    dispatch(getTransactionsFailedDetail())
  }, [ period ])

  return (
    <Sidebar show>
      <HeaderSidebar
        uppercase
        title={<><strong>Vendas</strong> falhadas</>}
        handleClose={() => history.push(onClosePath)}
      />
      {error
        ? <SidebarContainer><ErrorState
          iconSrc='/assets/icons/alerts/alert.svg'
          title='Desculpe não conseguimos carregar os gráficos.'
          description='Acho que aconteceu alguma coisa de errado.'
          color={darkGray}
          iconColor={darkerGray}
        /></SidebarContainer>
        : <KPITransactionsFailed
          period={period}
          color={color}
          pieChart={pieData || []}
          amount={toMoney(amount)}
          count={count}
          isLoading={request}
        />
      }
    </Sidebar>
  )
}

TransactionsFailed.propTypes = {
  history: PropTypes.object,
  onClosePath: PropTypes.string
}

TransactionsFailed.defaultProps = {
  onClosePath: '/vendas'
}

export default withRouter(TransactionsFailed)
