/* eslint react/prop-types: 0 */
import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { parseDate } from '../../Utils/Utils'
import { darkerGray, darkGray, white } from '../../Styles/settings/Constants'
import Label from '../../UIComponents/Label/Label'
import Grid from 'src/UIComponents/Grid/Grid'
import Table from '../Table/NewTable'
import { Tooltip } from '../../UIComponents/Tooltip'
import ToggleComponent from '../ToggleComponent/ToggleComponent'
import ButtonDefault from '../../UIComponents/Button/ButtonDefault'
import Can from '../../Helpers/Permission/Can'

const FeatureFlagsTable = ({ resourcesList,
  setResourcesList,
  loading,
  error,
  updatedAt,
  setShowDefaultSettingsPopup,
  setShowChangeSettingsPopup
}) => {
  const {
    apiSettings
  } = useSelector(({ settings }) => ({
    apiSettings: settings.items
  }))

  const tooltipAttributeConditions = {
    'posEnabled': apiSettings.posPairingEnable === false,
    'salesEnabled': apiSettings.disableBoleto === true,
    'plansFeesEnabled': apiSettings.disablePlans === true,
    'payoutEnabled': apiSettings.disableManualPayout === true || apiSettings.disableAutomaticPayout === true,
    'transfersEnabled': apiSettings.disableP2PTransfer === true || apiSettings.disablebankAccountTransfer === true
  }

  const COLUMNS = [
    {
      Header: 'Funcionalidade',
      id: 'attributeLabel',
      width: '70%',
      Cell: ({ cell }) => {
        if (cell.row.original.attributeName === 'receivableEnabled') {
          return <>
            <LabelWithTooltip>Agenda de recebíveis <InfoIcon data-tip data-for='value-tooltip'>?</InfoIcon></LabelWithTooltip>
            <Tooltip id='value-tooltip' >
              <p>É obrigatório oferecer a agenda</p>
              <p>de recebíveis segundo</p>
              <p>resolução 264 do Banco Central.</p>
            </Tooltip>
            </>
        }
        if (
          tooltipAttributeConditions[cell.row.original.attributeName]) {
          return <>
            <LabelWithTooltip>{ cell.row.original.attributeLabel} <InfoIcon data-tip data-for='value-tooltip2'>?</InfoIcon></LabelWithTooltip>
            <Tooltip id='value-tooltip2' >
              <p >A parametrização desta funcionalidade está indisponível</p>
              <p>por conta de uma configuração mandatória da Zoop</p>
              <p>Em caso de dúvidas, entre em contato conosco.</p>
            </Tooltip>
          </>
        } if (cell.row.original.attributeName === 'balancesEnabled') {
          return null
        } else {
          return cell.row.original.attributeLabel
        }
      }
    },
    {
      Header: 'Status',
      width: '15%',
      Cell: ({ cell }) => {
        if (cell.row.original.attributeName === 'receivableEnabled') {
          return (
            <StatusContainer>
              Ativado
              <ToggleComponent
                handleOnChange={
                  (value) => handleToggleOnChange(cell.row.original.attributeName, value)
                }
                defaultStatus
                onlyACtive
              />
            </StatusContainer>
          )
        }
        if (tooltipAttributeConditions[cell.row.original.attributeName]) {
          return (
            <StatusContainer>
              Desativado
              <ToggleComponent
                handleOnChange={
                  (value) => handleToggleOnChange(cell.row.original.attributeName, value)
                }
                defaultStatus={false}
              />
            </StatusContainer>
          )
        } if (cell.row.original.attributeName === 'balancesEnabled') {
          return null
        } else {
          return (
            <StatusContainer >
              {cell.row.original.active ? 'Ativado' : 'Desativado'}
              <ToggleComponent
                handleOnChange={
                  (value) => handleToggleOnChange(cell.row.original.attributeName, value)
                }
                defaultStatus={cell.row.original.active}
                disabled
              />
            </StatusContainer>
          )
        }
      }
    },
    {
      accessor: 'void',
      width: '20%'
    }
  ]

  const handleToggleOnChange = (attributeName, value) => {
    const updatedResources = resourcesList.map((resource) => {
      if (resource.attributeName === attributeName) {
        return {
          ...resource,
          active: value
        }
      }
      return resource
    })
    setResourcesList(updatedResources)
  }

  return (
    <>
      <Grid.Row>
        <Grid.Col offset={1} cols={15}>
          <Table
            loading={loading}
            tableColumns={COLUMNS}
            tableData={resourcesList}
            emptyState={{ message: 'Nenhum recurso encontrado' }}
            error={error}
            data-tracking={'CustomizeResources'}
            showPagination={false}
            defaultSortBy={[
              { id: 'attributeLabel', desc: false }
            ]}
          />
        </Grid.Col>
      </Grid.Row>

      {updatedAt !== null && (
        <Grid.Row>
          <Grid.Col offset={12} cols={15}>
            <Label color={darkerGray} overflow>Última alteração feita em {parseDate(updatedAt, 'DD [de] MMMM [de] YYYY [às] HH:mm:ss[h]')}.</Label>
          </Grid.Col>
        </Grid.Row>
      )}

      <Can I='read' a='FeatureFlag'>
        {!error && (
          <Grid.Row>
            <Grid.Col offset={1} cols={15}>
              <ButtonContainer >
                <ButtonDefault ghost outline widthAuto onClick={() => setShowDefaultSettingsPopup(true)}>Voltar a configuração padrão</ButtonDefault>
                <ButtonDefault
                  onClick={() => setShowChangeSettingsPopup(true)}
                >Salvar alteração</ButtonDefault>
              </ButtonContainer>
            </Grid.Col>
          </Grid.Row>
        )}
      </Can>
    </>
  )
}

FeatureFlagsTable.defaultProps = {}

FeatureFlagsTable.propTypes = {}

export const LabelWithTooltip = styled.div`
  display: flex;
  align-items: center;
`

export const InfoIcon = styled.div`
  padding: 0px 4px;
  background-color: ${darkGray};
  color: ${white};
  border-radius: 3px;
  margin-left: 5px;
  font-size: 10px;
  height: fit-content;
`

const StatusContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  height: 30px;
  width: 130px;

  div{
  margin:0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap:20px;
  padding: 40px;
  height: 10px;
  margin-top: 0;
 
  button{
  width: auto;
  padding: 12px 24px  12px  24px;
  }
`
FeatureFlagsTable.propTypes = {
  resourcesList: PropTypes.array,
  setResourcesList: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  updatedAt: PropTypes.string,
  setShowDefaultSettingsPopup: PropTypes.func,
  setShowChangeSettingsPopup: PropTypes.func
}

export { FeatureFlagsTable as Table }
