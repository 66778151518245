import React from 'react'
import t from 'tcomb-form'
import Select from '../../UIComponents/Select/Select'

class SelectFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <Select
        marginTop
        id={locals.config.id}
        disabled={locals.config.disabled}
        white={locals.config.white}
        black={locals.config.black}
        label={locals.label}
        value={locals.value}
        options={locals.config.options}
        placeholder={locals.config.placeholder || ''}
        hasError={locals.hasError}
        errorMessage={locals.error}
        lock={locals.config.lock}
        viewMode={locals.config.viewMode}
        onChange={item => {
          locals.onChange(item)
        }}
      />
    )
  }
}

export default SelectFactory
