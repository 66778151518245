import React from 'react'
import t from 'tcomb-form'
import InputForm from '../../UIComponents/InputForm/InputForm'

const MAXLENGTH = 9

class PostalCodeFactory extends t.form.Component {
  PostalCodeMask = value => {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{5})(\d)/, '$1-$2')
    return value
  }

  _maskedValue = value => {
    value = this.PostalCodeMask(value)
    return value
  }

  getTemplate () {
    return (locals) => (
      <InputForm
        id={locals.config.id}
        type={locals.type}
        label={locals.label}
        value={this._maskedValue(locals.value)}
        placeholder={locals.config.placeholder || ''}
        disabled={locals.config.disabled}
        onBlur={locals.config.onBlur}
        hasError={locals.hasError}
        errorMessage={locals.error}
        lock={locals.config.lock}
        onChange={(e) => {
          let { value } = e.target

          if (value.length > MAXLENGTH) return
          value = value.replace(/\D/g, '')
          locals.onChange(value)
        }}
      />
    )
  }
}

export default PostalCodeFactory
