import React, { useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import appConfig from 'src/Configs/appConfig'
import { logout } from '../../Actions/authentication'
import { getProfileFromMarketplace } from 'src/Helpers/Permissions'

import { HeaderUserUI } from 'src/UIComponents/HeaderUser'
import { isLocal, removeAccount } from 'src/Utils/Utils'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import MyProfileComponent from 'src/Components/MyProfileComponent/MyProfileComponent'

const HeaderUser = () => {
  const {
    user,
    marketplace,
    users,
    marketplaceId
  } = useSelector(({ user, marketplace, access, context }) => {
    const fullName = user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.firstName
        ? user.firstName
        : user.lastName
    return {
      user: {
        name: fullName || user.username,
        userName: user.username,
        permissions: user.permissions
      },
      marketplaceId: (context.marketplace && context.marketplace.id) || marketplace.id,
      marketplace: {
        name: marketplace.details.name,
        details_name: marketplace.details.customer.statement_descriptor,
        document: marketplace.details.customer.ein
      },
      users: removeAccount({ userId: user.id, marketplaceId: marketplace.id }, access.connectedAccounts)
    }
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const [openSidebar, setOpenSidebar] = useState(false)
  const profile = getProfileFromMarketplace(marketplaceId, user.permissions)
  const canAccessConfig = profile !== 'full_access_operation'

  const handleUserSelect = user => {
    const { marketplaceSlug } = user

    if (isLocal()) {
      window.open(`http://${marketplaceSlug}.${appConfig.domain}:8080`)
    } else {
      window.open(`https://${marketplaceSlug}.${appConfig.domain}`)
    }
  }

  const handleClickConfig = () => {
    history.push('/configuracoes')
  }

  return (
    <Fragment>
      <HeaderUserUI
        user={user}
        users={users}
        marketplace={marketplace}
        onUserSelect={handleUserSelect}
        logout={() => dispatch(logout())}
        onMyProfileClick={() => setOpenSidebar(true)}
        handleClickConfig={handleClickConfig}
        enableConfig={canAccessConfig}
      />
      <Sidebar show={openSidebar}>
        <HeaderSidebar
          uppercase
          title={<><strong>Meu</strong> Perfil</>}
          handleClose={() => setOpenSidebar(false)}
          data-test='my-profile-text'
        />
        <SidebarContainer>
          <MyProfileComponent />
        </SidebarContainer>
      </Sidebar>
    </Fragment>
  )
}

export default HeaderUser
