import styled from 'styled-components'
import Label from 'src/UIComponents/Label/Label'
import { lightGray, darkerGray } from 'src/Styles/settings/Constants'
import ButtonDefault from 'src/UIComponents/Button/ButtonDefault'

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
`

export const Display = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 717px;
  max-height: 331px;
`

export const TitleContainer = styled.div`
  flex: 1;
  text-align: end;
`

export const Title = styled(Label)`
  font-size: 37px;
  font-weight: bolder;
  text-transform: uppercase;
`

export const Divider = styled.div`
  float: left;
  height: 300px;
  width: 1px;
  margin: 0 4rem;
  background-color: ${lightGray};
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex: 1;
`

export const Message = styled.span`
  font-size: 1.8rem;
  color: ${darkerGray};
  margin-bottom: 1.7rem;
`

export const Button = styled(ButtonDefault)`
  width: 140px
`
