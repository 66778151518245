import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from '../../../UIComponents/Modal/Modal'
import ButtonDefault from '../../../UIComponents/Button/ButtonDefault'
import Label from '../../../UIComponents/Label/Label'
import { bigText, darkerGray, smallerText } from '../../../Styles/settings/Constants'
import Can from '../../../Helpers/Permission/Can'

export const ForgotToSavePopup = ({ handleClose, handleSaveChanges }) => {
  return (
    <Modal styleProps={{ maxWidth: '494px', height: '385px', padding: '40px', display: 'flex' }}>
      <MainContainer>
        <Label fontSize={bigText} textAlign={'center'}><strong>Ei, você não salvou!</strong></Label>
        <Label
          color={darkerGray}
          textAlign={'center'}
          width={'65%'}
        >
         As alterações que não foram salvas, serão perdidas ao sair da página. <strong>Deseja salvar?</strong>
        </Label >
        <AlertContainer>
          <Label color={darkerGray}
            textAlign={'center'} fontSize={smallerText}>
            Salvar altera a visibilidade dos recursos para <strong>todos os estabelecimentos</strong>.

          </Label>
        </AlertContainer>

        <ButtonsContainer auto>
          <ButtonDefault widthAuto outline ghost onClick={handleClose}>
          Sair sem salvar
          </ButtonDefault>
          <Can I='read' a='FeatureFlag'>
            <ButtonDefault widthAuto onClick={handleSaveChanges}>
          Quero salvar
            </ButtonDefault>
          </Can>
        </ButtonsContainer>
      </MainContainer>
    </Modal>
  )
}

export const MainContainer = styled.div`
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:10px;
`
const AlertContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 16px 28px 16px 28px;
  gap: 10px;
  border-radius: 5px;
  background-color:#F1F1F1 ;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

ForgotToSavePopup.propTypes = {
  handleClose: PropTypes.func,
  handleSaveChanges: PropTypes.func
}
