import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getTransfersFailedDetail } from 'src/Actions/kpi'

import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import KPITransfersFailed from 'src/UIComponents/KPIDetails/KPITransfersFailed'
import ErrorState from '../../UIComponents/ErrorState/ErrorState'

import { darkerGray, darkGray } from '../../Styles/settings/Constants'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const TransfersFailed = ({ history }) => {
  const { color } = useSelector(({ custom }) => ({
    color: custom.color
  }))

  const { period, amount, count, groupedBy, request, error } = useSelector(
    ({ kpi: { period, transfers: { failed } } }) => ({
      period,
      ...failed
    })
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTransfersFailedDetail())
  }, [ period ])

  return (
    <Sidebar show>
      <HeaderSidebar
        uppercase
        title={<><strong>Transferências</strong> Falhadas</>}
        handleClose={() => history.push('/transferencias')}
      />
      {error
        ? <SidebarContainer><ErrorState
          iconSrc='/assets/icons/alerts/alert.svg'
          title='Desculpe não conseguimos carregar os gráficos.'
          description='Acho que aconteceu alguma coisa de errado.'
          color={darkGray}
          iconColor={darkerGray}
        /></SidebarContainer>
        : <KPITransfersFailed
          period={period}
          amount={amount}
          count={count}
          color={color}
          pieData={groupedBy}
          isLoading={request}
        />
      }
    </Sidebar>
  )
}

TransfersFailed.propTypes = {
  history: PropTypes.object
}

export default withRouter(TransfersFailed)
