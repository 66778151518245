import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getTransactionsDisputedDetail } from '../../Actions/kpi'

import { toMoney, parseDateHistogram } from '../../Utils/Utils'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import KPITransactionsDisputed from '../../UIComponents/KPIDetails/KPITransactionsDisputed' // ALTERAR !!!!!!!!!!! //
import ErrorState from '../../UIComponents/ErrorState/ErrorState'

import { darkGray, darkerGray } from 'src/Styles/settings/Constants'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const TransactionsDisputed = ({ history, onClosePath }) => {
  const dispatch = useDispatch()
  const {
    color,
    period,
    amount,
    count,
    topHits,
    historic,
    request,
    error,
    sellerId
  } = useSelector(({ custom, kpi: { period, transactions: { dispute } }, context: { seller } }) => ({
    ...custom,
    ...dispute,
    period,
    sellerId: seller?.id
  }))

  const dataChart = parseDateHistogram(historic, period)

  useEffect(() => {
    dispatch(getTransactionsDisputedDetail())
  }, [ period ])

  return (
    <Sidebar show>
      <HeaderSidebar
        uppercase
        title={<><strong>Vendas</strong> em disputa</>}
        handleClose={() => history.push(onClosePath)}
      />
      {error
        ? <SidebarContainer><ErrorState
          iconSrc='/assets/icons/alerts/alert.svg'
          title='Desculpe não conseguimos carregar os gráficos.'
          description='Acho que aconteceu alguma coisa de errado.'
          color={darkGray}
          iconColor={darkerGray}
        /></SidebarContainer>
        : <KPITransactionsDisputed
          period={period}
          color={color}
          dataChart={dataChart}
          topHits={sellerId ? undefined : topHits}
          amount={toMoney(amount)}
          count={count}
          isLoading={request}
        />
      }
    </Sidebar>
  )
}

TransactionsDisputed.propTypes = {
  history: PropTypes.object,
  onClosePath: PropTypes.string
}

TransactionsDisputed.defaultProps = {
  onClosePath: '/vendas'
}

export default withRouter(TransactionsDisputed)
