import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getTransfersToTheMarketplaceDetail } from 'src/Actions/kpi'

import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import KPITransfersToTheMarketplace from 'src/UIComponents/KPIDetails/KPITransfersToTheMarketplace'
import ErrorState from '../../UIComponents/ErrorState/ErrorState'

import { darkerGray, darkGray } from '../../Styles/settings/Constants'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const TransfersToTheMarketplace = ({ history }) => {
  const { color } = useSelector(({ custom }) => ({
    color: custom.color
  }))

  const { period, amount, count, groupedBy, request, error } = useSelector(
    ({ kpi: { period, transfers: { toTheMarketplace } } }) => ({
      ...toTheMarketplace,
      period
    })
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTransfersToTheMarketplaceDetail())
  }, [ period ])

  return (
    <Sidebar show>
      <HeaderSidebar
        uppercase
        title={<><strong>Transferências</strong> para o marketplace</>}
        handleClose={() => history.push('/transferencias')}
      />
      {error
        ? <SidebarContainer><ErrorState
          iconSrc='/assets/icons/alerts/alert.svg'
          title='Desculpe não conseguimos carregar os gráficos.'
          description='Acho que aconteceu alguma coisa de errado.'
          color={darkGray}
          iconColor={darkerGray}
        /></SidebarContainer>
        : <KPITransfersToTheMarketplace
          period={period}
          amount={amount}
          count={count}
          barChartData={groupedBy}
          color={color}
          isLoading={request}
        />
      }
    </Sidebar>
  )
}

TransfersToTheMarketplace.propTypes = {
  history: PropTypes.object
}

export default withRouter(TransfersToTheMarketplace)
