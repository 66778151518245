import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from '../../../UIComponents/Modal/Modal'
import ButtonDefault from '../../../UIComponents/Button/ButtonDefault'
import Label from '../../../UIComponents/Label/Label'
import { bigText, darkerGray } from '../../../Styles/settings/Constants'

export const ReturnDefaultConfigPopup = ({ handleNotSavePopup, handleDefaultSettings, setShowDefaultSettingsPopup }) => {
  return (
    <Modal handleOnClose={() => setShowDefaultSettingsPopup(false)} styleProps={{ maxWidth: '364px', height: '307px', padding: '40px', display: 'flex' }}>
      <MainContainer>
        <Label fontSize={bigText} textAlign={'center'}><strong>Voltar a configuração padrão</strong></Label>
        <Label
          color={darkerGray}
          textAlign={'center'}
          width={'82%'}
        >
       Esta ação irá restaurar as configurações para o padrão com todas as funcionalidades ativas. <strong> Deseja continuar?</strong>
        </Label >
        <ButtonsContainer auto>
          <ButtonDefault widthAuto outline ghost onClick={handleNotSavePopup}>
           Não salvar
          </ButtonDefault>
          <ButtonDefault widthAuto onClick={handleDefaultSettings}>
           Salvar alterações
          </ButtonDefault>
        </ButtonsContainer>
      </MainContainer>
    </Modal>
  )
}

export const MainContainer = styled.div`
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:24px;
`

const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

ReturnDefaultConfigPopup.propTypes = {
  handleNotSavePopup: PropTypes.func,
  handleDefaultSettings: PropTypes.func,
  setShowDefaultSettingsPopup: PropTypes.func
}
