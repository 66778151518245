import React from 'react'
import t from 'tcomb-form'
import InputForm from '../../UIComponents/InputForm/InputForm'

class InputDefaultFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <InputForm
        id={locals.config.id}
        type={locals.config.type}
        label={locals.label}
        value={locals.value}
        maxLength={locals.config.maxLength}
        placeholder={locals.config.placeholder || ''}
        disabled={locals.config.disabled}
        isValid={locals.config.valid}
        hasError={locals.hasError}
        errorMessage={locals.error}
        onBlur={locals.config.onBlur}
        lock={locals.config.lock}
        data-test={locals.config.id}
        onChange={(e) => {
          let { value } = e.target
          locals.onChange(value)
        }}
      />
    )
  }
}

export default InputDefaultFactory
