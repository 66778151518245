import React from 'react'
import t from 'tcomb-form'
import CheckBoxForm from '../../UIComponents/Checkbox/CheckboxForm'

class CheckboxFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <CheckBoxForm
        id={locals.config.id}
        label={locals.label}
        checked={locals.value}
        disabled={locals.config.disabled}
        hasError={locals.hasError}
        errorMessage={locals.error}
        onClick={() => locals.onChange(!locals.value)}
      />
    )
  }
}

export default CheckboxFactory
