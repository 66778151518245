import React from 'react'
import t from 'tcomb-form'
import InputForm from '../../UIComponents/InputForm/InputForm'

const MAXLENGTH = 15
const PHONE_LENGTH = 13

class PhoneFactory extends t.form.Component {
  PhoneMask = value => {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '($1) $2')
    if (value.length <= PHONE_LENGTH) {
      value = value.replace(/(\d{4})(\d)/, '$1-$2')
    } else {
      value = value.replace(/(\d{5})(\d)/, '$1-$2')
    }
    return value
  }

  _maskedValue = value => {
    if (value) value = this.PhoneMask(value)
    return value
  }

  getTemplate () {
    return (locals) => (
      <InputForm
        id={locals.config.id}
        type={locals.type}
        label={locals.label}
        value={this._maskedValue(locals.value)}
        placeholder={locals.config.placeholder || ''}
        disabled={locals.config.disabled}
        hasError={locals.hasError}
        errorMessage={locals.error}
        lock={locals.config.lock}
        onChange={(e) => {
          let { value } = e.target

          if (value.length > MAXLENGTH) return
          value = value.replace(/\D/g, '')
          locals.onChange(value)
        }}
      />
    )
  }
}

export default PhoneFactory
