import React, { Fragment, useEffect, useState } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { getLastCommission } from 'src/Actions/commissions'
import { getQuerystring } from 'src/Helpers/Router/Tools'

import MetricsDateRangeSelector from 'src/Components/MetricsDateRangeSelector/MetricsDateRangeSelector'
import TransfersToTheMarketplace from 'src/Components/KPIs/TransfersToTheMarketplace'
import TransfersFailed from 'src/Components/KPIs/TransfersFailed'
import TransactionsSucceeded from 'src/Components/KPIs/TransactionsSucceeded'
import TransactionsFailed from 'src/Components/KPIs/TransactionsFailed'
import TransactionsChargedBack from 'src/Components/KPIs/TransactionsChargedBack'
import TransactionsDisputed from '../KPIs/TransactionsDisputed'
import TransfersSucceeded from 'src/Components/KPIs/TransfersSucceeded'
import CommissionSidebar from 'src/Components/CommissionSidebar/CommissionSidebar'
import HeaderInfoUI from 'src/UIComponents/HeaderInfo/HeaderInfo'

const HeaderInfo = ({ title, infoResume, customInfo, actions }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const typeKpi = getQuerystring()
  const [showCommissionHistory, setShowCommissionHistory] = useState(false)

  const appear = location.pathname.match(/kpi/)
  const {
    lastCommission,
    lastCommissionRequesting,
    hasSellerContext
  } = useSelector(({ commissions, context }) => {
    return {
      lastCommission: commissions.lastCommission,
      lastCommissionRequesting: commissions.lastCommissionRequesting,
      hasSellerContext: context.seller
    }
  })

  useEffect(() => {
    dispatch(getLastCommission())
  }, [])

  const handleCommissionClick = () => {
    setShowCommissionHistory(true)
    history.push(history.location.pathname + '/markups/historico')
  }

  const onClosePath = location.pathname.substring(0, location.pathname.lastIndexOf('/'))

  return (
    <Fragment>
      <HeaderInfoUI
        title={title}
        customInfo={customInfo}
        dateSelector={<MetricsDateRangeSelector />}
        actions={actions}
        infoResume={infoResume}
        onClickCommissionDetail={handleCommissionClick}
        hasSellerContext={hasSellerContext}
        commission={lastCommission}
        commissionRequesting={lastCommissionRequesting}
      />
      <TransitionGroup appear={appear}>
        <CSSTransition
          key={location.key}
          in={showCommissionHistory}
          classNames='modal-transition'
          timeout={500}>
          {
            state => (
              <Switch location={location}>
                <Route
                  exact
                  path='/:routePath/markups/historico'
                  render={() => <CommissionSidebar state={state} />}
                />
                <Route
                  path={['*/visao-geral/kpi', '/home/kpi']}
                  exact
                  render={() => {
                    if (typeKpi.type === 'succeeded') return <TransactionsSucceeded onClosePath={onClosePath} />
                    if (typeKpi.type === 'failed') return <TransactionsFailed onClosePath={onClosePath} />
                    if (typeKpi.type === 'chargedBack') return <TransactionsChargedBack onClosePath={onClosePath} />
                    if (typeKpi.type === 'dispute') return <TransactionsDisputed onClosePath={onClosePath} />
                  }}
                />
                <Route
                  path='*/vendas/kpi'
                  exact
                  render={() => {
                    if (typeKpi.type === 'succeeded') return <TransactionsSucceeded onClosePath={onClosePath} />
                    if (typeKpi.type === 'failed') return <TransactionsFailed onClosePath={onClosePath} />
                    if (typeKpi.type === 'chargedBack') return <TransactionsChargedBack onClosePath={onClosePath} />
                    if (typeKpi.type === 'dispute') return <TransactionsDisputed onClosePath={onClosePath} />
                  }}
                />
                <Route
                  path='*/transferencias/kpi'
                  render={() => {
                    if (typeKpi.type === 'failed') return <TransfersFailed onClosePath={onClosePath} />
                    if (typeKpi.type === 'succeeded') return <TransfersSucceeded onClosePath={onClosePath} />
                    if (typeKpi.type === 'toTheMarketplace') return <TransfersToTheMarketplace onClosePath={onClosePath} />
                  }}
                />
              </Switch>
            )
          }
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  )
}

HeaderInfo.propTypes = {
  title: PropTypes.string,
  infoResume: PropTypes.array,
  customInfo: PropTypes.node,
  actions: PropTypes.any
}

export default HeaderInfo
