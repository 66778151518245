import React, { Fragment } from 'react'
import t from 'tcomb-form'
import styled from 'styled-components'
import SelectBankAccount from 'src/Components/SelectBankAccount/SelectBankAccount'

import { darkGray, red } from '../../Styles/settings/Constants'

class SelectBankAccountFactory extends t.form.Component {
  getTemplate() {
    return (locals) => {
      return (
        <Fragment>
          <Label>{locals.label}</Label>
          {locals.config.options.map((option) => {
            return (
              <SelectBankAccount
                option={option}
                key={option.id || option.card_id}
                checked={option.id === locals.value.id && option.card_id === locals.value.card_id}
                // eslint-disable-next-line no-unused-vars
                onChange={(e) => locals.onChange(option)}
                loading={locals.config.loading}
              />
            )
          })}
          {locals.hasError && <Error data-test={`radio-input-error-${locals.label}`}>{locals.error}</Error>}
        </Fragment>
      )
    }
  }
}

const Label = styled.label`
  color: ${darkGray};
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`

const Error = styled.span`
  margin-top: 4px;
  color: ${red};
  font-size: 12px;
  display: flex;
  line-height: 16px;
`

export default SelectBankAccountFactory
