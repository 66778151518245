import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { resetContext } from 'src/Actions/context'
import Logo from '../../UIComponents/Logo/Logo'

const LogoComponent = ({ big }) => {
  const dispatch = useDispatch()
  const { logo, temporaryColoredLogo, fullName } = useSelector(({ marketplace, custom }) => ({
    logo: custom.logo,
    temporaryColoredLogo: custom.temporaryColoredLogo,
    fullName: marketplace.details ? marketplace.details.customer.business_name : ''
  }))

  const path = '/'

  return (
    <Logo
      big={big}
      logo={temporaryColoredLogo ? temporaryColoredLogo.preview : logo}
      name={fullName}
      path={path}
      onClick={() => dispatch(resetContext())}
    />
  )
}

LogoComponent.propTypes = {
  big: PropTypes.bool
}

export default LogoComponent
