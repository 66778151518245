import React from 'react'
import t from 'tcomb-form'
import InputForm from '../../UIComponents/InputForm/InputForm'

class DocumentFactory extends t.form.Component {
  CPFMask = value => {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return value
  }

  CNPJMask = value => {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1/$2')
    value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2')
    return value
  }

  _maskedValue = (value) => {
    if (value) {
      if (value.length <= 11) {
        value = this.CPFMask(value)
      } else {
        value = this.CNPJMask(value)
      }
    }

    return value
  }

  getTemplate () {
    return (locals) => (
      <InputForm
        id={locals.config.id}
        type={locals.type}
        label={locals.label}
        value={this._maskedValue(locals.value, locals.config.documentType)}
        placeholder={locals.config.placeholder || ''}
        disabled={locals.config.disabled}
        hasError={locals.hasError}
        errorMessage={locals.error}
        isValid={locals.config.valid}
        onBlur={locals.config.onBlur}
        lock={locals.config.lock}
        onChange={(e) => {
          let { value } = e.target
          value = value.replace(/\D/g, '')
          if (value.length > 14) return

          locals.onChange(value)
        }}
      />
    )
  }
}

export default DocumentFactory
