import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getTransfersSucceededDetail } from 'src/Actions/kpi'

import { parseDateHistogram } from '../../Utils/Utils'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import KPITransfersSucceeded from 'src/UIComponents/KPIDetails/KPITransfersSucceeded'
import ErrorState from '../../UIComponents/ErrorState/ErrorState'

import { darkerGray, darkGray } from '../../Styles/settings/Constants'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const TransfersSucceeded = ({ history }) => {
  const dispatch = useDispatch()

  const { color, period, amount, count, groupedBy, historic, request, error } = useSelector(
    ({ custom, kpi: { period, transfers: { succeeded } } }) => ({
      ...custom,
      ...succeeded,
      period
    })
  )

  useEffect(() => {
    dispatch(getTransfersSucceededDetail())
  }, [ period ])

  const data = parseDateHistogram(historic, period)

  return (
    <Sidebar show>
      <HeaderSidebar
        uppercase
        title={<><strong>Transferências</strong> Pagas</>}
        handleClose={() => history.push('/transferencias')}
      />
      {error
        ? <SidebarContainer><ErrorState
          iconSrc='/assets/icons/alerts/alert.svg'
          title='Desculpe não conseguimos carregar os gráficos.'
          description='Acho que aconteceu alguma coisa de errado.'
          color={darkGray}
          iconColor={darkerGray}
        /></SidebarContainer>
        : <KPITransfersSucceeded
          amount={amount}
          count={count}
          color={color}
          dataChart={data}
          barChartData={groupedBy}
          isLoading={request}
          period={period}
        />
      }
    </Sidebar>
  )
}

TransfersSucceeded.propTypes = {
  history: PropTypes.object
}

export default withRouter(TransfersSucceeded)
