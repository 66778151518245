import React, { Fragment } from 'react'
import t from 'tcomb-form'
import styled from 'styled-components'
import RadioForm from '../../UIComponents/Radio/RadioForm'

import { darkGray, red } from '../../Styles/settings/Constants'

class RadioGroupFactory extends t.form.Component {
  getTemplate () {
    return (locals) => (
      <Fragment>
        <Label>{locals.label}</Label>
        <RadioContainer>
          {locals.config.options.map(option => (
            <RadioForm
              key={option.value}
              id={option.value}
              label={option.label}
              value={option.value}
              checked={option.value === locals.value}
              disabled={locals.config.disabled}
              hasError={locals.hasError}
              errorMessage={locals.error}
              onChange={e => locals.onChange(e.currentTarget.value)}
            />
          ))}
        </RadioContainer>
        {locals.hasError && <Error data-test={`radio-input-error-${locals.label}`} >{locals.error}</Error>}
      </Fragment>
    )
  }
}

const Label = styled.label`
  color: ${darkGray};
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`

const RadioContainer = styled.div`
  display: flex;
  width: 100%;
`

const Error = styled.span`
  margin-top: 4px;
  color: ${red};
  font-size: 12px;
  display: flex;
  line-height: 16px;
`

export default RadioGroupFactory
